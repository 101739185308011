<template>
  <section class="active-positions">
    <section class="active-positions__group">
      <positions-skeleton v-if="isFetchingShortlistPositions" />
      <template v-else>
        <div class="active-positions__filters">
          <sort-by-criteria-select
            data-test="sort-by"
            @new-sort-criteria="updateSorting"
          />

          <app-searchable-select
            v-model="localEmployerIdFilter"
            class="active-positions__employers-select"
            name="employerId"
            :items="employersList"
            data-test="employers-select"
            @input="emitActivePositionsFilterEvent"
          />
        </div>
        <div class="active-positions__heading" data-test="shortlist-position-header">
          <div
            class="active-positions__heading-title"
            v-if="hasShortlistPositions"
            data-test="shortlist-header"
          >
            <h2 class="active-positions__heading-title-text">
              {{ $t(`${langPath}.positionsWithShortlistTitle`) }}
            </h2>

            <ev-badge
              class="active-positions__heading-title-badge"
              :title="$t(`${langPath}.newBadge`)"
            />
          </div>
        </div>

        <ev-loader
          v-if="isSorting"
          data-test="loader"
          title=""
          :message="$t(`${langPath}.isSorting`)"
        />
        <ul
          v-else-if="hasShortlistPositions"
          class="active-positions__items"
        >
          <li
            v-for="position in shortlistPositions"
            :key="position.id"
            class="active-positions__item"
          >
            <position-shortlist-card
              :data-test-position-id="position.id"
              :position="position"
              v-on="$listeners"
            />
          </li>
          <li class="active-positions__new-position-item">
            <article class="active-positions__new-position-card">
              <header class="active-positions__new-position-card-heading">
                <ev-icon
                  name="file-plus-alt"
                  color="red"
                />

                <h3 class="active-positions__new-position-card-title">
                  {{ $t(`${langPath}.createNewPositionTitle`) }}
                </h3>
              </header>

              <div class="active-positions__new-position-card-action">
                <ev-link
                  size="small"
                  color="red"
                  variant="secondary"
                  a-href="javascript:Appcues.show('430801a3-afa0-4a67-8842-69b86ede2d9f')"
                >
                  {{ $t(`${langPath}.createNewPositionButton`) }}
                </ev-link>
              </div>
            </article>
          </li>
        </ul>

        <div class="active-positions__pagination">
          <ev-button
            v-if="hasNextPageShortlist"
            data-test="next-page-button"
            variant="tertiary"
            color="blue"
            @click="$emit('fetchShortlistPositionsPage')"
          >
            {{ $t(`${langPath}.nextPage`) }}
          </ev-button>
        </div>
      </template>
    </section>

    <section class="active-positions__group">
      <positions-skeleton v-if="isFetchingPositions" />

      <template v-else>
        <div class="active-positions__heading">
          <h2 class="active-positions__heading-title-text">
            {{ $t(`${langPath}.positionsTitle`) }}
          </h2>
        </div>

        <ev-loader
          v-if="isSorting"
          data-test="loader"
          title=""
          :message="$t(`${langPath}.isSorting`)"
        />
        <ul
          v-else-if="hasPositions"
          class="active-positions__items"
        >
          <li
            v-for="position in activePositions"
            :key="position.id"
            class="active-positions__item"
          >
            <position-card
              :data-test-position-id="position.id"
              :position="position"
              v-on="$listeners"
            />
          </li>
        </ul>
        <empty-state
          v-else
          class="active-positions__empty-state"
          data-test="empty-state"
          :title="emptyStateTitle"
          :message="emptyStateMessage"
          :call-to-action-text="$t(`${langPath}.createPosition.callToAction`)"
          :call-to-action-event-name="emptyStateEventName"
          @create-position-clicked="redirectToPositionCreate"
        />
        <section class="active-positions__pagination">
          <div class="active-positions__see-more">
            <ev-button
              v-if="hasNextPage"
              data-test="next-page-button"
              variant="tertiary"
              color="blue"
              @click="$emit('nextActivePositionsPage')"
            >
              {{ $t(`${langPath}.nextPage`) }}
            </ev-button>
          </div>
        </section>
      </template>
    </section>
  </section>
</template>

<script>
import EvBadge from '@revelotech/everestV2/EvBadge'
import EvButton from '@revelotech/everestV2/EvButton'
import EvLink from '@revelotech/everestV2/EvLink'
import EvIcon from '@revelotech/everestV2/EvIcon'
import EvLoader from '@revelotech/everestV2/EvLoader'
import EmptyState from './components/EmptyState'
import PositionCard from './components/PositionCard'
import PositionShortlistCard from './components/PositionShortlistCard'
import PositionsSkeleton
  from '@/components/Skeletons/PositionsSkeleton'
import SortByCriteriaSelect from './components/SortByCriteriaSelect'

import { createNamespacedHelpers } from 'vuex'
import { CURRENT_USER, POSITIONS, EMPLOYERS } from '@/store/namespaces'

const positionsHelper = createNamespacedHelpers(POSITIONS)
const employersHelper = createNamespacedHelpers(EMPLOYERS)
const currentUserHelper = createNamespacedHelpers(CURRENT_USER)

export default {
  name: 'ActivePositions',
  components: {
    EvBadge,
    EvButton,
    EvLink,
    EvIcon,
    EvLoader,
    EmptyState,
    PositionCard,
    PositionShortlistCard,
    PositionsSkeleton,
    SortByCriteriaSelect
  },
  data () {
    return {
      langPath: __langpath,
      localEmployerIdFilter: null
    }
  },
  computed: {
    ...positionsHelper.mapState([
      'activePositions',
      'activePositionsPage',
      'activePositionsTotalPages',
      'shortlistPositions',
      'shortlistPositionsPage',
      'shortlistPositionsTotalPages',
      'employerIdFilter',
      'isFetchingPositions',
      'isFetchingShortlistPositions',
      'isSorting'
    ]),
    ...employersHelper.mapState(['employers']),
    ...currentUserHelper.mapState(['currentUser']),
    hasPositions () {
      return this.activePositions.length > 0
    },
    hasShortlistPositions () {
      return this.shortlistPositions.length > 0
    },
    hasNextPage () {
      return this.activePositionsTotalPages > this.activePositionsPage
    },
    hasNextPageShortlist () {
      return this.shortlistPositionsTotalPages > this.shortlistPositionsPage
    },
    employersList () {
      const allPositionsOption = {
        value: null, label: this.$t(`${this.langPath}.filter.allPositions`)
      }

      return [{ items: [allPositionsOption, ...this.filteredEmployers] }]
    },
    filteredEmployers () {
      return this.employers
        .filter((employer) => employer.id !== this.currentUser.id)
        .map((employer) => ({
          label: employer.name,
          value: employer.id
        }))
    },
    selectedEmployer () {
      return this.employers.find(
        employer => employer.id === this.localEmployerIdFilter
      )
    },
    filtersByEmployer () {
      return this.localEmployerIdFilter !== null
    },
    emptyStateTitle () {
      if (this.filtersByEmployer) {
        return this.$t(
          `${this.langPath}.emptyState.titleWithEmployerName`,
          { employerName: this.selectedEmployer?.name }
        )
      }

      return this.$t(`${this.langPath}.emptyState.title`)
    },
    emptyStateEventName () {
      if (this.filtersByEmployer) return ''

      return 'create-position-clicked'
    },
    emptyStateMessage () {
      if (this.filtersByEmployer) return ''

      return this.$t(`${this.langPath}.emptyState.description`)
    }
  },
  async created () {
    await this.getEmployers()
  },
  mounted () {
    this.localEmployerIdFilter = this.employerIdFilter
    this.$emit('fetchShortlistPositions')
  },
  methods: {
    ...employersHelper.mapActions(['getEmployers']),
    ...positionsHelper.mapActions(['startSorting']),
    redirectToPositionCreate () {
      this.$smartlook('track', 'clicked_on_create_position')

      this.$router.push({ path: '/positions/create' })
    },
    emitActivePositionsFilterEvent (employerId) {
      if (employerId === null) {
        this.$emit('fetchActivePositions')
      } else {
        this.$emit('fetchActivePositionsByEmployer', employerId)
      }
    },
    updateSorting () {
      this.startSorting()
      this.$emit('updated-sort-by')
    }
  }
}
</script>

<style lang="scss" scoped>
.active-positions {
  display: flex;
  flex-direction: column;
  margin-top: $base * 6;

  &__group {
    &:not(:last-child) {
      margin-bottom: $base * 10;
    }
  }

  &__heading {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &-title {
      align-items: center;
      display: flex;

      &-text {
        font: $tx-title-2;
      }

      &-badge {
        margin-left: $base * 7;
      }
    }
  }

  &__filters {
    align-self: flex-end;
    display: flex;
    margin-bottom: $base * 6;
  }

  &__employers-select {
    @include margin(left, 4);

    align-self: flex-end;
  }

  &__items {
    align-items: stretch;
    display: flex;
    flex-flow: wrap;
    gap: $base * 6;
    margin-top: $base * 10;
  }

  &__new-position-card {
    --card-min-height: 260px;

    background-color: $bg-white;
    border-radius: $b-radius;
    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: space-between;
    min-height: var(--card-min-height);
    padding: $base * 6;

    @include breakpoint(md) {
      width: 190px;
    }

    &-heading {
      display: flex;
      flex-flow: column;
    }

    &-title {
      font: $tx-title-3;
      margin-top: $base * 4;
    }

    &-action {
      margin-top: $base * 10;
    }
  }

  &__pagination {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
